/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import "../../../styles/base.scss";
import Header from "../header/header";
import Footer from "../footer/footer";
import Contacts from "../../shared/contacts/contacts";

const Layout = ({ children, page }) => {
  return (
    <>
      <Header/>
        <main>{children}</main>
      <Contacts page={page}/>
      <Footer/>
    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
