import React from "react";
import "./contacts-img-seven.scss";
import { StaticQuery, graphql } from 'gatsby';

class ContactsImgSeven extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                     emailImg: file(relativePath: { eq: "email.svg" }) {
      publicURL
    },
                }
              `}
        render={data => (
          <img alt="" className="contacts-left-content-img" src={data.emailImg.publicURL}/>
        )}
      />
    );
  }
}

export default ContactsImgSeven

