import React from "react";
import "./contacts-img-six.scss";
import { StaticQuery, graphql } from 'gatsby';

class ContactsImgSix extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                     telImg: file(relativePath: { eq: "tel.svg" }) {
      publicURL
    },
                }
              `}
        render={data => (
          <img alt="" className="contacts-left-content-img" src={data.telImg.publicURL}/>
        )}
      />
    );
  }
}

export default ContactsImgSix

