import React from "react";
import "./contacts-img-two.scss";
import { StaticQuery, graphql } from 'gatsby';

class ContactsImgTwo extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                     contactsBrownImg: file(relativePath: { eq: "contacts-brown-img.svg" }) {
      publicURL
    },
                }
              `}
        render={data => (
          <img alt="" className="contacts-brown-img" src={data.contactsBrownImg.publicURL}/>
        )}
      />
    );
  }
}

export default ContactsImgTwo

