import React from "react";
import "./contacts-img-four.scss";
import { StaticQuery, graphql } from 'gatsby';

class ContactsImgFour extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                     contactsBgTwo: file(relativePath: { eq: "contacts-bg-two.png" }) {
      publicURL
    },
                }
              `}
        render={data => (
          <img alt="" className={this.props.page === 'stories' ? 'contacts-bg-two-hide success-background' : 'contacts-bg-two'} id="contact" src={data.contactsBgTwo.publicURL} />
        )}
      />
    );
  }
}

export default ContactsImgFour

