import React from "react";
import "./contacts-img-one.scss";
import { StaticQuery, graphql } from 'gatsby';

class ContactsImgOne extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                     contactsYellowBgGrid: file(relativePath: { eq: "contacts-yellow-bg-grid.svg" }) {
      publicURL
    },
                }
              `}
        render={data => (
          <img alt="" className="contacts-yellow-bg-grid" src={data.contactsYellowBgGrid.publicURL}/>
        )}
      />
    );
  }
}

export default ContactsImgOne

