import React from "react";
import "./contacts-img-three.scss";
import { StaticQuery, graphql } from 'gatsby';

class ContactsImgThree extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                     contactsCircles: file(relativePath: { eq: "contacts-circles.svg" }) {
      publicURL
    },
                }
              `}
        render={data => (
          <img alt="" className="contacts-circles" src={data.contactsCircles.publicURL}/>
        )}
      />
    );
  }
}

export default ContactsImgThree

