import React from "react";
import "./contacts.scss";
import ContactsImgOne from './contacts-imgs/contacts-img-one/contacts-img-one';
import ContactsImgTwo from './contacts-imgs/contacts-img-two/contacts-img-two';
import ContactsImgThree from './contacts-imgs/contacts-img-three/contacts-img-three';
import ContactsImgFour from './contacts-imgs/contacts-img-four/contacts-img-four';
import ContactsImgFive from './contacts-imgs/contacts-img-five/contacts-img-five';
import ContactsImgSix from './contacts-imgs/contacts-img-six/contacts-img-six';
import ContactsImgSeven from './contacts-imgs/contacts-img-seven/contacts-img-seven';
// import ContactsImgTwelve from './contacts-imgs/contacts-img-twelve/contacts-img-twelve';
import Recaptcha from 'react-google-invisible-recaptcha';

class Contacts extends React.Component {
    state = {
      fullName: '',
      email: '',
      company: '',
      message: '',
      error: '',
      messageSent: false
    };

    handleChange = input => e => {
      this.setState({[input]: e.target.value});
      if(this.state.fullName !== '' && this.state.email !== '' && this.state.company !== '') {
          this.setState({error : ''});
      }
    };

    onSubmit = event => {
      if(this.state.fullName === '' || this.state.email === '' || this.state.company === '') {
        this.setState({error : 'Please complete all required fields.'});
        this.recaptcha.reset();
        event.preventDefault();        
      } else {
          this.setState({error : ''});
        this.recaptcha.execute();
      }
    };

    onResolved = () => {
      this.setState({messageSent: true});
      // ... process data here ... //
      // alert( 'Recaptcha resolved with response: ' + this.recaptcha.getResponse() );
    };

    render() {
      return (
        <div className={this.props.page === 'stories' ? 'contacts-container-success' : 'contacts-container'}>
           {this.props.page === 'stories' ? "" :
            <div className="contacts-bg-one">
              <ContactsImgOne/>
              <ContactsImgTwo/>
              <ContactsImgThree/>
             </div>
          }
          <ContactsImgFour page={this.props.page} />
           <div className={this.props.page === 'stories' ? 'contacts-left-right-container container contacts-left-right-container-hide' : 'contacts-left-right-container container'}>
            <div className="contacts-left"
                 data-sal="slide-right"
                 data-sal-easing="ease"
                 data-sal-duration="1000"
            >
              <h5 className="contacts-pre-title">get in touch</h5>
              <h2 className="contacts-title">Let’s work together</h2>
              <div className="contacts-left-content">
                <ContactsImgFive className="contacts-left-content-img" id="location-img"/>
                <div className="location-text">
                  <p className="location-text-p contacts-left-content-text">2021 Fillmore St</p>
                  <p className="location-text-p contacts-left-content-text">ste 1335</p>
                  <p className="location-text-p contacts-left-content-text">San Francisco, CA 94115</p>
                </div>
              </div>
              <div className="contacts-left-content">
                <ContactsImgSix className="contacts-left-content-img"/>
                <p className="contacts-left-content-text">Tel: 888-80-CODE-0</p>
              </div>
              <div className="contacts-left-content">
                <ContactsImgSeven/>
                <p className="contacts-left-content-text">Email: info@paladinsolutions.io</p>
              </div>
              {/*<div className="reach-us-content">*/}
              {/*  <p className="reach-us-title">Reach us here</p>*/}
              {/*  <div className="reach-us-icons-container">*/}
              {/*    <div className="reach-us-icons">*/}
              {/*      <ul className="reach-us-icons-ul">*/}
              {/*        <li className="reach-us-icons-li">*/}
              {/*          <a href="https://www.linkedin.com/company/golden-ratio-systems">*/}
              {/*            <ContactsImgTwelve/>*/}
              {/*          </a>*/}
              {/*        </li>*/}
              {/*      </ul>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className="contacts-right"
                 data-sal="slide-left"
                 data-sal-easing="ease"
                 data-sal-duration="1000"
            >
              <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
              <div className="contact-form-container">
                <div className="contact-form-top">
                  <p className="contact-form-title">We'd love to hear from you!</p>
                </div>
                <div className="contact-form-user-data">
                
                
                  <input type="hidden" name="oid" value="00D4W000006i25q" />
                  <input type="hidden" name="retURL" value="https://www.goldenratio.systems/thank-you" />
                  <input id="lead_source" name="lead_source" type="hidden" value="GRS Website" />

                  <input id="last_name" name = "last_name" value={this.state.fullName} onChange={this.handleChange('fullName')} maxLength="80" className="user-data" type="text" placeholder="Name*"/>
                  <input id="email" name = "email" value={this.state.email} onChange={this.handleChange('email')} maxLength="80" className="user-data" type="text" placeholder="Email*"/>
                  <input id="company" name = "company" value={this.state.company} onChange={this.handleChange('company')} maxLength="80" className="user-data" type="text" placeholder="Company*"/>
                  <input id="00N4W00000Kz6C8" name="00N4W00000Kz6C8" value={this.state.message} onChange={this.handleChange('message')} className="user-data" type="text" placeholder="Message"/>
                  <div className="contact-form-note">* Indicates required fields</div>
                  <div className="contact-form-error">{this.state.error}</div>

                  <button onClick={this.onSubmit} className="user-data-btn">Submit</button>
                  
                </div>
              </div>
              </form>
            </div>
          </div>
          <Recaptcha
            className="recaptcha"
            ref={ref => this.recaptcha = ref}
            sitekey="6LeCsfomAAAAALXEjoEmX25QKxPhyF2Tsopc5vLG"
            onResolved={this.onResolved}
            locale="en"
          >
          </Recaptcha>
        </div>
      )
    }

}

export default Contacts
